export interface ITemplate {
  _id?: string;
  bot_name: string;
  name: string;
  channel: ITemplateChannels;
  status: ITemplateStatus;
  creation_date: Date;
  description: string;
  language: ITemplateLanguages;


  payload_message: IPayloadMessage;
}

export interface IPayloadMessage {
  bot: IPayloadBot;
  template: IPayloadTemplate | null;
  text?: string;
}

interface IPayloadBot {
  bot_name: string;
  group: string;
  block: string;
}

export interface IPayloadTemplate {
  name: string;
  components: ITemplateComponents[];
  language?: ITemplateLanguages;
  category?: ITemplateCategories;
  example?: string;
  broker?: ITemplateBrokers;
}

type TemplateComponentTypes =
  | 'BODY'
  | 'HEADER'
  | 'FOOTER'
  | 'BUTTONS'
  | 'IMAGE';

export type TemplateComponentFormat =
  | 'NONE'
  | 'TEXT'
  | 'IMAGE'
  | 'DOCUMENT'
  | 'VIDEO';

export interface ITemplateComponents {
  type: TemplateComponentTypes;
  text?: string;
  format?: TemplateComponentFormat;
  example?: any;
  buttons?: ITemplateButton[];
}

export type ITemplateButtonTypes =
  | 'NONE'
  | 'PHONE_NUMBER'
  | 'URL'
  | 'QUICK_REPLY';

export type ITemplateButtonFields = keyof ITemplateButton;
export interface ITemplateButton {
  type: ITemplateButtonTypes;
  text: string | null;
  url: string | null;
  phone_number: string | null;
}

export type ITemplateChannels =
  | 'whatsapp'
  | 'email'
  | 'sms'
  | 'falazap'
  | 'ura';

export type ITemplateStatus =
  | 'approved'
  | 'rejected'
  | 'pending'
  | 'in_appeal'
  | 'pending_deletion'
  | 'deleted'
  | 'disabled'
  | 'paused'
  | 'limit_exceeded';

export type ITemplateLanguages = 'es_US' | 'pt_BR' | 'es';

export type ITemplateCategories =
  | 'MARKETING'
  | 'OTP'
  | 'UTILITY';

export type ITemplateBrokers = 'Infobip' | 'Blip' | 'Twillio' | 'Meta' | 'Interaxa'

export interface IGetTemplatesRequest
  extends Pick<ITemplate, 'bot_name'> {}

export interface IGetTemplatesResponse {
  data: ITemplate[];
}

export interface IGetOneTemplateRequest
  extends Pick<ITemplate, '_id' |  'bot_name'> {}

export interface IGetOneTemplateResponse {
  data: ITemplate;
}

export interface ICreateTemplateRequest extends ITemplate {
  already_in_meta?: boolean;
}

export interface ICreateTemplateResponse {
  data: ITemplate;
}

export interface IUpdateTemplateRequest extends ITemplate {}

export interface IUpdateTemplateResponse {
  data: ITemplate;
}

export interface IDeleteTemplateRequest
  extends Pick<ITemplate, 'bot_name' |  '_id'> {}

export interface IGetTemplateOnDatabaseRequest
  extends Pick<ITemplate, 'bot_name' | 'name'> {}

export interface IGetTemplateOnMetaRequest
  extends Pick<ITemplate, 'bot_name' | 'name'> {}
export interface IGetTemplateOnMetaResponse {
  data: TemplateOnMeta;
}
interface TemplateOnMeta {
  category: ITemplateCategories;
  components: ITemplateComponents[];
  text: string;
  type: TemplateComponentTypes;
  id: string;
  language: ITemplateLanguages;
  name: string;
  status: string;
}

export const emptyTemplate: ITemplate = {
  bot_name: '',
  payload_message: {
    bot: {
      bot_name: '',
      group: '',
      block: '',
    },
    template: {
      name: '',
      category: 'MARKETING',
      components: [],
      example: '',
      language: 'pt_BR',
      broker: 'Meta',
    },
  },
  name: '',
  channel: 'whatsapp',
  status: 'pending',
  creation_date: new Date(),
  description: '',
  language: 'pt_BR',
  
};
