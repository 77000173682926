import { ChangeEvent, useEffect, useState } from 'react';

import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import MttButton from 'components/Material/MttButton/MttButton';

import CheckIcon from '@material-ui/icons/Check';

import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  CreateCampaign,
  UpdateCampaign,
} from 'services/CampaignService/CampaignsService';
import {
  ICampaign,
  ICampaignStatus,
  ISenderChannel,
  ITemplateVariable,
  emptyCampaign,
} from 'services/CampaignService/CampaignsService/types';
import { GetTemplates } from 'services/CampaignService/TemplatesService';
import {
  ITemplate,
  emptyTemplate,
} from 'services/CampaignService/TemplatesService/types';
import useTranslator from 'utils/hooks/Translator';
import {
  BoxContainer,
  NewCampaignContainer,
  NewCampaignFields,
  StyledGridButtons,
  StyledRightButtons,
  TextContainer,
} from '../../styles';
import * as S from '../styles';

interface Props {
  handleClose: () => void;
  currentCampaign: ICampaign;
}

interface ICampaignError {
  name: string;
  template: string;
}

export function CreateOrEditCampaign({ handleClose, currentCampaign }: Props) {
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification, state } = useFlow();
  const {  botName } = state;
  const bot_name = botName || '';
  const [error, setError] = useState<ICampaignError>({
    name: '',
    template: '',
  });
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(currentCampaign || emptyCampaign);
  const [listTemplates, setListTemplates] = useState<ITemplate[]>([]);

  const currentTemplate =
    listTemplates.find((template) => template.name === campaign.template) ||
    emptyTemplate;

  const templateBody =
    currentTemplate.payload_message.template?.components.find(
      (component) => component.type === 'BODY'
    )?.text;

  const templateMessage =
    currentTemplate.channel === 'whatsapp' || 'falaZap'
      ? templateBody
      : currentTemplate.payload_message.text;


  const handleCampaignColumnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    setCampaign((prev) => ({
      ...prev,
      sender: {
        column: input,
        channel: prev.sender.channel || 'email',
      },
    }));
  };

  useEffect(() => {
    loadTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadTemplates() {
    setLoading(true);

    try {
      const response = await GetTemplates({ bot_name }, dispatchApp);

      if (response.Success) {
        const allTemplates = response.Data.data;

        setListTemplates(allTemplates);
      } else throw new Error('erro');
    } catch (error: any) {
      toastNotification('error', getTranslation('toast.error.searchTemplates'));
    }

    setLoading(false);
  }

  useEffect(() => {
    if (templateMessage) {
      const campaignVariables: ITemplateVariable[] = [];
      const regexp = /{{\d?}}/g;
      const foundVariables = templateMessage.match(regexp);
      const currCampaignVariables = campaign.variables || [];

      const externalVariables = campaign.variables?.filter(
        (variable) => !foundVariables?.includes(variable.variable)
      );

      if (foundVariables) {
        foundVariables?.forEach((variable, index) => {
          campaignVariables.push({
            variable,
            column: currCampaignVariables[index]?.column || '',
          });
        });

        externalVariables?.forEach((variable) =>
          campaignVariables.push(variable)
        );

        setCampaign((prev) => ({
          ...prev,
          variables: campaignVariables,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate]);

  async function handleCreateCampaign() {
    setLoading(true);
    const response = await CreateCampaign(campaign, dispatchApp);

    if (response.Success) {
      toastNotification(
        'success',
        getTranslation('toast.success.campaignCreated'),
        3000
      );
      handleClose();
    } else {
      toastNotification('error', getTranslation('toast.error.campaignCreated'));
    }
    setLoading(false);
  }

  async function handleUpdateCampaign() {
    setLoading(true);
    const response = await UpdateCampaign(campaign, dispatchApp);

    if (response.Success) {
      toastNotification(
        'success',
        getTranslation('toast.success.campaignUpdated'),
        3000
      );
      setLoading(false);
      handleClose();
    } else {
      toastNotification('error', getTranslation('toast.error.campaignUpdated'));
      setLoading(false);
    }
  }

  function handleChangeColumn(index: number, newColumn: string) {
    const newVariables = campaign.variables;
    if (newVariables) {
      newVariables[index].column = newColumn;

      setCampaign((prev) => ({
        ...prev,
        variables: newVariables,
      }));
    }
  }

  function handleChangeTemplate(newTemplate: string) {
    setCampaign((prev) => ({
      ...prev,
      template: newTemplate,
    }));
  }

  function validateCampaign(campaign: ICampaign) {
    const errors: ICampaignError = { name: '', template: '' };

    if (campaign.name === '') {
      errors.name = getTranslation('validations.required', {
        field: getTranslation('name'),
      });
    }

    if (campaign.template === '') {
      errors.template = getTranslation('validations.required', {
        field: 'Template',
      });
    }

    setError(errors);
    if (errors.name === '' && errors.template === '')
      campaign._id ? handleUpdateCampaign() : handleCreateCampaign();
  }

  return (
    <NewCampaignContainer>
      <NewCampaignFields>
        <BoxContainer>
          <TextContainer style={{ maxWidth: 'fit-content' }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={campaign.status}
                onChange={(e) =>
                  setCampaign((prev) => ({
                    ...prev,
                    status: e.target.value as ICampaignStatus,
                  }))
                }
                label="Status"
              >
                <MenuItem value={'active'}>{getTranslation('active')}</MenuItem>
                <MenuItem value={'inactive'}>
                  {getTranslation('inactive')}
                </MenuItem>
              </Select>
            </FormControl>
          </TextContainer>
        </BoxContainer>
        <BoxContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              label={getTranslation('name')}
              fullWidth
              value={campaign.name}
              onChange={(e) =>
                setCampaign((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              helperText={error.name}
              error={!!error.name}
            />
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              label={getTranslation('description')}
              fullWidth
              value={campaign.description}
              onChange={(e) =>
                setCampaign((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <TextContainer>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="template-id">Template</InputLabel>
              <Select
                labelId="template-id"
                id="demo-simple-select-outlined"
                value={campaign.template}
                onChange={(e) => handleChangeTemplate(e.target.value as string)}
                label="Template"
                error={!!error.template}
              >
                {listTemplates.map((template) => (
                  <MenuItem key={template._id} value={template.name}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="template-id" error={!!error.template}>
                {error.template}
              </FormHelperText>
            </FormControl>
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              fullWidth
              value={templateMessage}
              disabled
            />
          </TextContainer>
        </BoxContainer>

        <BoxContainer>
          <Typography
            variant="h6"
            children={getTranslation('modal.campaigns.template.senderField')}
            color="primary"
          />
        </BoxContainer>
        <BoxContainer>
          <TextContainer>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="sender-id">
                {getTranslation('modal.campaigns.template.senderField')}
              </InputLabel>
              <Select
                labelId="sender-id"
                id="demo-simple-select-outlined"
                value={campaign.sender.channel}
                onChange={(e) =>
                  setCampaign((prev) => ({
                    ...prev,
                    sender: {
                      channel: e.target.value as ISenderChannel,
                      column: campaign.sender.column || '',
                    },
                  }))
                }
                label={getTranslation('modal.campaigns.template.senderField')}
              >
                <MenuItem value={'phone'}>{getTranslation('phone')}</MenuItem>
                <MenuItem value={'email'}>Email</MenuItem>
                <MenuItem value={'device_id'}>
                  {getTranslation('deviceID')}
                </MenuItem>
              </Select>
            </FormControl>
          </TextContainer>
          <TextContainer>
            <TextField
              variant="outlined"
              label="Nome do campo"
              fullWidth
              value={campaign.sender.column}
              onChange={handleCampaignColumnChange}
            />
          </TextContainer>
        </BoxContainer>

        {campaign.variables && campaign.variables.length > 0 && (
          <S.BoxVariableAndColumnContainer>
            <S.BoxVariableAndColumn>
              <BoxContainer>
                <Typography
                  variant="h6"
                  children={getTranslation(
                    'modal.campaigns.campaign.templateVariables'
                  )}
                  color="primary"
                />
              </BoxContainer>
              {campaign.variables?.map((variable) => (
                <TextContainer key={`${variable.variable}-${variable.column}`}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={variable.variable}
                  />
                </TextContainer>
              ))}
            </S.BoxVariableAndColumn>

            <S.BoxVariableAndColumn>
              <BoxContainer>
                <Typography
                  variant="h6"
                  children={getTranslation('column')}
                  color="primary"
                />
              </BoxContainer>
              {campaign.variables?.map((variable, index) => (
                <TextContainer key={index}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={variable.column}
                    onChange={(e) => handleChangeColumn(index, e.target.value)}
                  />
                </TextContainer>
              ))}
            </S.BoxVariableAndColumn>
          </S.BoxVariableAndColumnContainer>
        )}
      </NewCampaignFields>

      <StyledGridButtons>
        <StyledRightButtons>
          <MttButton
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => validateCampaign(campaign)}
          >
            {getTranslation('footerButtons.saveAndExit')}
            {loading && <CircularProgress color="inherit" size={18} />}
          </MttButton>
        </StyledRightButtons>
      </StyledGridButtons>
    </NewCampaignContainer>
  );
}
