import {
  AppBar,
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

import * as SS from '../styles';
import * as S from './styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import MttButton from 'components/Material/MttButton/MttButton';
import useTranslator from 'utils/hooks/Translator';

import {
  Add,
  Delete,
  Edit,
  Refresh,
  Search,
  Visibility,
} from '@material-ui/icons';

import { CircularProgress } from '@material-ui/core';

import ActioningIcon from 'components/Icons/ActioningIcon';
import CampaignIcon from 'components/Icons/CampaignIcon';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { isBefore } from 'date-fns';
import { GetCampaigns } from 'services/CampaignService/CampaignsService';
import {
  DeleteTemplate,
  GetTemplates,
  GetTemplateStatus,
} from 'services/CampaignService/TemplatesService';
import {
  emptyTemplate,
  ITemplate,
  ITemplateChannels,
  ITemplateStatus,
} from 'services/CampaignService/TemplatesService/types';
import { formatDateHour } from 'utils/Date';
import { SaveOrEditTemplate } from './CreateOrEditTemplate';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalTemplatesProps {
  open: boolean;
  close: () => void;
}

export function ModalTemplates({ open, close }: ModalTemplatesProps) {
  const { hasPermissionToAction } = usePermissions();
  const classes = SS.useStyles();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification, dispatch, state } = useFlow();

  const { botName } = state;

  const bot_name = botName || '';

  const { getTranslation } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModalCreateOrEditTemplate, setOpenModalCreateOrEditTemplate] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState(emptyTemplate);
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const isCommunicationGuidelineWrite = hasPermissionToAction({
    company: state.companyName!,
    agent: state.botName!,
    action: ['communication_guideline:write'],
  });

  const filteredTemplates =
    search.length > 0
      ? templates.filter((template) => template.name.includes(search))
      : templates;

  useEffect(() => {
    if (!openModalCreateOrEditTemplate) loadTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, openModalCreateOrEditTemplate]);

  async function loadTemplates() {
    setLoading(true);

    try {
      const response = await GetTemplates({ bot_name }, dispatchApp);
      if (response.Success) {
        const allTemplates = response.Data.data;

        setTemplates(
          allTemplates.sort((a, b) => {
            if (
              isBefore(
                new Date(a.creation_date).getTime(),
                new Date(b.creation_date).getTime()
              )
            )
              return 1;
            else return -1;
          })
        );
      } else throw new Error('erro');
    } catch (error: any) {
      toastNotification('error', getTranslation('toast.error.searchTemplates'));
    }

    setTemplates((prev) =>
      prev.sort((a, b) => {
        if (
          isBefore(
            new Date(a.creation_date).getTime(),
            new Date(b.creation_date).getTime()
          )
        )
          return 1;
        else return -1;
      })
    );

    setLoading(false);
  }

  function handleClose() {
    setPage(0);
    close();
  }

  function handleNewTemplate() {
    setCurrentTemplate({
      ...emptyTemplate,
      bot_name,
    });
    setOpenModalCreateOrEditTemplate(true);
  }

  function handleCloseNewTemplate() {
    setTemplates([]);
    setOpenModalCreateOrEditTemplate(false);
  }

  function handleChangeRowsPerPage(e: { target: { value: string | number } }) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function handleChangePage(event: any, newPage: React.SetStateAction<number>) {
    setPage(newPage);
  }

  async function templateIsUsed(template: ITemplate): Promise<boolean> {
    const response = await GetCampaigns({ bot_name }, dispatchApp);
    let templateUsed = false;

    if (response.Success) {
      const campaigns = response.Data.data;

      const foundTemplate = campaigns.find(
        (campaign) => campaign.template === template.name
      );

      if (foundTemplate) templateUsed = true;
      else templateUsed = false;
    }

    return templateUsed;
  }

  async function handleRemoveTemplate(template: ITemplate) {
    const templateUsed = await templateIsUsed(template);

    if (!templateUsed) {
      const response = await DeleteTemplate({ ...template }, dispatchApp);

      if (response.Success) {
        toastNotification(
          'success',
          getTranslation('toast.success.templateDeleted')
        );
        setTemplates(templates.filter((temp) => temp._id !== template._id));
      } else {
        toastNotification(
          'error',
          getTranslation('toast.error.templateDeleted')
        );
      }
    } else
      toastNotification('error', getTranslation('toast.error.usedTemplate'));
  }

  function handleEditTemplate(template: ITemplate) {
    setCurrentTemplate(template);
    setOpenModalCreateOrEditTemplate(true);
  }

  function getColor(status: ITemplateStatus) {
    switch (status) {
      case 'approved':
        return '#79e16d';
      case 'pending':
        return '#ffb84d';
      case 'in_appeal':
        return '#0071b3';
      case 'pending_deletion':
        return '#ffb84d';
      case 'deleted':
        return '#535353';
      case 'disabled':
        return '#b1b1b1';
      case 'paused':
        return '#f3f02d';
      case 'limit_exceeded':
        return '#8a0202';
      case 'rejected':
      default:
        return '#d80101';
    }
  }

  function getPrettyChannel(channel: ITemplateChannels) {
    switch (channel) {
      case 'ura':
        return 'URA';
      case 'email':
        return 'E-mail';
      case 'sms':
        return 'SMS';
      case 'whatsapp':
        return 'WhatsApp';
      case 'falazap':
      default:
        return 'FalaZap';
    }
  }

  function getPrettyStatus(status: ITemplateStatus) {
    switch (status) {
      case 'approved':
        return getTranslation('approved');
      case 'rejected':
        return getTranslation('rejected');
      case 'in_appeal':
        return getTranslation('in_appeal');
      case 'pending_deletion':
        return getTranslation('pending_deletion');
      case 'deleted':
        return getTranslation('deleted');
      case 'disabled':
        return getTranslation('disabled');
      case 'paused':
        return getTranslation('paused');
      case 'limit_exceeded':
        return getTranslation('limit_exceeded');
      case 'pending':
      default:
        return getTranslation('pending');
    }
  }

  async function handleRefreshStatus(template: ITemplate) {
    const response = await GetTemplateStatus({ ...template }, dispatchApp);

    if (response.Success) {
      const templateIndex = templates.findIndex(
        (temp) => temp._id === template._id
      );
      const statusResponse = response.Data.data.status;
      const currentStatus = template.status;

      if (currentStatus !== statusResponse) {
        const updatedTemplate = {
          ...template,
          status: response.Data.data.status,
        };

        const listTemplates = templates;

        if (templateIndex !== -1) {
          listTemplates[templateIndex] = updatedTemplate;
          setTemplates(listTemplates);
        }

        toastNotification(
          'success',
          getTranslation('toast.success.statusUpdate')
        );
      } else {
        toastNotification(
          'warning',
          getTranslation('toast.warning.statusUpdate')
        );
      }
    } else {
      toastNotification('error', getTranslation('toast.error.statusUpdate'));
    }
  }

  const renderTemplatesList = () => (
    <SS.ContainerList>
      <SS.SearchAndTableContainer>
        <SS.ContainerHeader>
          <Typography variant="h6" color="primary">
            {getTranslation('templatesList')}
          </Typography>
          <SS.HeaderButtonsContainer>
            {isCommunicationGuidelineWrite && (
              <>
                <MttButton
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => handleNewTemplate()}
                >
                  {getTranslation('modal.campaigns.template.newButton')}
                </MttButton>
              </>
            )}
          </SS.HeaderButtonsContainer>
        </SS.ContainerHeader>
        <SS.GridSearch>
          <Autocomplete
            options={templates.map((template) => template.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                value={search}
                label={getTranslation('search')}
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </SS.GridSearch>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="inherit" size={20} />
          </div>
        ) : templates.length > 0 ? (
          <>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                aria-label="simple table"
                size="medium"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {getTranslation('name')}
                    </TableCell>
                    <TableCell align="center">
                      {getTranslation('channel')}
                    </TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">
                      {getTranslation('creationDate')}
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredTemplates.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredTemplates
                  ).map((template, index) => (
                    <TableRow key={index}>
                      <TableCell scope="template" align="center">
                        {template.name}
                      </TableCell>
                      <TableCell align="center">
                        {getPrettyChannel(template.channel)}
                      </TableCell>
                      <TableCell align="center">
                        <S.StatusDiv>
                          <S.Status bgColor={getColor(template.status)} />
                          {getPrettyStatus(template.status)}
                        </S.StatusDiv>
                      </TableCell>
                      <TableCell align="center">
                        {formatDateHour(template.creation_date)}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={getTranslation(
                            !template._id ? 'edit' : 'view'
                          )}
                        >
                          <IconButton
                            onClick={() => handleEditTemplate(template)}
                          >
                            {!template._id ? <Edit /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                        {isCommunicationGuidelineWrite && (
                          <Tooltip title={getTranslation('delete')}>
                            <IconButton
                              onClick={() => handleRemoveTemplate(template)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}

                        {getPrettyChannel(template.channel) === 'WhatsApp' && (
                          <Tooltip title={'Refresh Status'}>
                            <IconButton
                              onClick={() => handleRefreshStatus(template)}
                            >
                              <Refresh />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <p style={{ marginTop: 24 }}>{getTranslation('noTemplatesResult')}</p>
        )}
      </SS.SearchAndTableContainer>

      <div className={classes.tableFooter}>
        <div>
          <Tooltip title={getTranslation('campaign')}>
            <IconButton
              onClick={() => {
                dispatch({ type: 'closeModalCampaignTemplates' });
                dispatch({ type: 'openModalCampaignCampaign' });
              }}
            >
              <CampaignIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={getTranslation('actionings')}>
            <IconButton
              onClick={() => {
                dispatch({ type: 'closeModalCampaignTemplates' });
                dispatch({ type: 'openModalCampaignActioning' });
              }}
            >
              <ActioningIcon />
            </IconButton>
          </Tooltip>
        </div>
        {templates.length > 0 && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={filteredTemplates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={getTranslation(
                  'modal.conversationHistory.linesPerPage'
                )}
              />
            </TableRow>
          </TableFooter>
        )}
      </div>
    </SS.ContainerList>
  );

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} fullScreen>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Templates
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={
                openModalCreateOrEditTemplate
                  ? () => handleCloseNewTemplate()
                  : () => handleClose()
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SS.ContainerDialog>
          {openModalCreateOrEditTemplate ? (
            <SaveOrEditTemplate
              handleClose={handleCloseNewTemplate}
              currentTemplate={currentTemplate}
            />
          ) : (
            renderTemplatesList()
          )}
        </SS.ContainerDialog>
      </Dialog>
    </>
  );
}
