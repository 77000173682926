import { ChangeEvent, useEffect, useState } from 'react';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import MttButton from 'components/Material/MttButton/MttButton';

import CheckIcon from '@material-ui/icons/Check';

import useTranslator from 'utils/hooks/Translator';

import {
  emptyTemplate,
  IPayloadTemplate,
  ITemplate,
  ITemplateBrokers,
  ITemplateButton,
  ITemplateButtonFields,
  ITemplateButtonTypes,
  ITemplateCategories,
  ITemplateChannels,
  ITemplateComponents,
  ITemplateLanguages,
  TemplateComponentFormat,
} from 'services/CampaignService/TemplatesService/types';

import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetBotsGroups } from 'services/BotService';
import {
  CreateTemplate,
  GetTemplateOnDatabase,
  GetTemplateOnMeta,
  UpdateTemplate
} from 'services/CampaignService/TemplatesService';
import { GetGroup } from 'services/FlowService';
import {
  BoxContainer,
  StyledGridButtons,
  StyledRightButtons,
  TextContainer,
} from '../../styles';
import { ITemplateButtons, SaveOrEditTemplateProps } from './types';


import { LibraryAddOutlined } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { validateTemplateName } from 'utils/String';
import { getFileFromUrl, handleFileUpload } from 'utils/uploadFile';
import { FormFalazap } from './FormFalazap';
import { Preview } from './Preview';
import {
  DialogTitle,
  NewCampaignContainer,
  NewCampaignFields,
  NewCampaignFieldsContainer,
  useStyles
} from './styles';

export interface ITemplateError {
  name: string;
  organization: string;
  message?: string;
  body?: string;
  falazapTitle?: string;
}

export interface IComponents {
  body: string;
  footer: string;
  header: ITemplateComponents;
  buttons: ITemplateButtons;
  image: string | File;
}

export function SaveOrEditTemplate({
  handleClose,
  currentTemplate,
}: SaveOrEditTemplateProps) {
  const { hasPermissionToAction } = usePermissions();
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification, state } = useFlow();
  const { botName } = state;
  const [error, setError] = useState<ITemplateError>({
    name: '',
    organization: '',
  });
  const bot_name = botName || '';
  const classes = useStyles();
  const [importFile, setImportFile] = useState(false);

  const [template, setTemplate] = useState(
    {
      ...currentTemplate,
      payload_message: {
        ...currentTemplate.payload_message,
        bot: {
          ...currentTemplate.payload_message.bot,
          bot_name,
        },
      },
    } || emptyTemplate
  );

  const [templateImportName, setTemplateImportName] = useState<string>('')

  const [loading, setLoading] = useState(false);
  const [isLoadingTemplateImport, setIsLoadingTemplateImport] = useState(false);

  const [groups, setGroups] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<string[]>([]);
  const [file, setFile] = useState<File>();

  const currentHeader = template.payload_message.template?.components.find(
    (c) => c.type === 'HEADER'
  );

  const currentButtons = template.payload_message.template?.components.find(
    (c) => c.type === 'BUTTONS'
  );

  const [templateButtons, setTemplateButtons] = useState<ITemplateButtons>({
    type: getButtonType(currentButtons),
    buttons: currentButtons?.buttons || [],
  });

  const currentBody = template.payload_message.template?.components.find(
    (c) => c.type === 'BODY'
  );
  const currentIMAGE = template.payload_message.template?.components.find(
    (c) => c.type === 'IMAGE'
  );

  const currentFooter = template.payload_message.template?.components.find(
    (c) => c.type === 'FOOTER'
  );

  const header = currentHeader
    ? {
        ...currentHeader,
        text: !!currentHeader.text?.trim() ? currentHeader.text : '',
      }
    : ({
        text: '',
        type: 'HEADER',
        format: 'NONE',
      } as ITemplateComponents);

  const [templateComponents, setTemplateComponents] = useState<IComponents>({
    body: currentBody?.text || '',
    footer: currentFooter?.text || '',
    header,
    buttons: templateButtons,
    image: currentIMAGE?.text || '',
  });

  const [imageUrl, setImageUrl] = useState(
    templateComponents.header.text || ''
  );
  const [isTemplateOnDatabase, setIsTemplateOnDatabase] = useState(false);

  const isNotEditable = !hasPermissionToAction({
    company: state.companyName!,
    agent: state.botName!,
    action: ['communication_guideline:write'],
  });

  const isWhatsApp = template.channel === 'whatsapp';
  const isFalazap = template.channel === 'falazap';
  const isSms = template.channel === 'sms';

  const buttonsLimit =
    templateButtons.type === 'QUICK_REPLY'
      ? 3
      : templateButtons.type === 'PHONE_NUMBER'
      ? 2
      : 0;

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (template.payload_message.bot.group)
      handleChangeGroup(template.payload_message.bot.group);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (importFile) {
      const documentHtml = document.getElementById('drawflow');
      const uploadInput = document.createElement('input');
      uploadInput.type = 'file';
      uploadInput.accept = 'application/csv';
      uploadInput.onchange = async (e: any) => {
        const file = e.target.files?.item(0);
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          const reader = new FileReader();
          reader.readAsText(file, 'UTF-8');
          setFile(file);
        } else {
          toastNotification(
            'error',
            getTranslation('toast.error.invalidFileFormat')
          );
        }
      };

      documentHtml?.appendChild(uploadInput);
      uploadInput.click();
      uploadInput.parentNode?.removeChild(uploadInput);
      setImportFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFile]);

  useEffect(() => {
    if (!file) return;
    const objectUrl = URL.createObjectURL(file);
    setImageUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  async function getGroups() {
    const response = await GetBotsGroups({ bot_name }, dispatchApp);

    if (response.Success && response.Data) {
      const flows = response.Data.data;

      const principal = flows.find((group) => group === 'principal');

      if (principal) {
        const updatedGroups = [
          principal,
          ...flows
            .filter((group) => group !== 'principal')
            .map((group) => group)
            .sort(),
        ];
        setGroups(updatedGroups);
      }
    }
  }

  function close() {
    setTemplate(emptyTemplate);
    handleClose();
  }

  async function verifyTemplateOnDatabase() {
    setLoading(true)

    const response = await GetTemplateOnDatabase(
        { 
          ...template,
          name: templateImportName.length > 0 ? templateImportName : template.name 
        },
        dispatchApp
      )

    if (response.Success && response.Data) {
      const responseTemplate = response.Data;

      const responseBody = responseTemplate.payload_message?.template?.components.find(c => c.type === 'BODY')?.text ||
        templateComponents.body

      const responseHeader = responseTemplate.payload_message?.template?.components?.find((c) => c.type === 'HEADER') ||
        templateComponents.header

      const responseFooter = responseTemplate.payload_message?.template?.components.find((c) => c.type === 'FOOTER')?.text ||
        templateComponents.footer

      if (responseHeader.format === 'IMAGE') {
        const url = responseHeader.example.header_handle[0];

        const file = await getFileFromUrl(url);
        const fileUpload = await handleFileUpload(file);

        if (!fileUpload) {
          toastNotification(
            'error',
            'Ocorreu um erro ao realizar o upload da imagem presente no Header e não será possível salvar o template, verifique e tente novamente.'
          );
          return;
        }

        responseHeader.text = fileUpload.url;
        setImageUrl(fileUpload.url);
      }

      setTemplateComponents((prev) => ({
        ...prev,
        body: responseBody,
        footer: responseFooter,
        header: responseHeader,
      }));

      const responseButtons = responseTemplate.payload_message?.template?.components.find((c) => c.type === 'BUTTONS')

      setTemplateButtons({
        type: getButtonType(responseButtons),
        buttons: responseButtons?.buttons || [],
      });

      setTemplate((prev) => ({
        ...prev,
        description: responseTemplate.description ?? prev.description,
        payload_message: {
          ...prev.payload_message,
          bot: {
            ...prev.payload_message.bot,
            ...responseTemplate.payload_message.bot
          },
          template: {
            ...prev.payload_message.template,
            category:
              responseTemplate.payload_message.template?.category ??
              prev.payload_message.template?.category,
            name: prev.payload_message?.template?.name ?? '',
            components: prev.payload_message?.template?.components ?? [],
          },
        },
      }))

      await handleChangeGroup(responseTemplate.payload_message.bot.group)

      toastNotification(
        'success',
        getTranslation('toast.warning.templateInMeta')
      );
      setIsTemplateOnDatabase(true);
    } else setIsTemplateOnDatabase(false);

    setLoading(false)
  }

  async function verifyTemplateOnMeta() {
    setIsLoadingTemplateImport(true)

    const response = await GetTemplateOnMeta(
      { 
        ...template,
        name: templateImportName
      },
      dispatchApp
    )

    if (response.Success && response.Data && response.Data.data) {
      const responseTemplate = response.Data.data;

      const responseBody =
        responseTemplate.components.find((c) => c.type === 'BODY')?.text ||
        templateComponents.body;

      const responseHeader =
        responseTemplate.components.find((c) => c.type === 'HEADER') ||
        templateComponents.header;

      const responseFooter =
        responseTemplate.components.find((c) => c.type === 'FOOTER')?.text ||
        templateComponents.footer;

      if (responseHeader.format === 'IMAGE') {
        const url = responseHeader.example.header_handle[0];

        const file = await getFileFromUrl(url);
        const fileUpload = await handleFileUpload(file);

        if (!fileUpload) {
          toastNotification(
            'error',
            'Ocorreu um erro ao realizar o upload da imagem presente no Header e não será possível salvar o template, verifique e tente novamente.'
          );
          return;
        }

        responseHeader.text = fileUpload.url;
        setImageUrl(fileUpload.url);
      }

      setTemplateComponents((prev) => ({
        ...prev,
        body: responseBody,
        footer: responseFooter,
        header: responseHeader,
      }));

      const responseButtons = responseTemplate.components.find(
        (c) => c.type === 'BUTTONS'
      );

      setTemplateButtons({
        type: getButtonType(responseButtons),
        buttons: responseButtons?.buttons || [],
      });

      setTemplate((prev) => ({
        ...prev,
        name: responseTemplate.name,
        language: responseTemplate.language,
        payload_message: {
          ...prev.payload_message,
          template: {
            ...prev.payload_message.template,
            category:
              responseTemplate.category ??
              prev.payload_message.template?.category,
            name: prev.payload_message?.template?.name || '',
            components: prev.payload_message?.template?.components || [],
          },
        },
      }));

      toastNotification(
        'success',
        getTranslation('toast.warning.templateInMeta')
      );

      setIsOpenModalImportTemplate(false)
    } else {
      toastNotification(
        'warning',
        getTranslation('toast.warning.templateIsNotInMeta')
      );
    }

    setIsLoadingTemplateImport(false)
  }

  async function handleCreateTemplate(template: ITemplate) {
    setLoading(true);

    const response = await CreateTemplate(
      { ...template, already_in_meta: isTemplateOnDatabase },
      dispatchApp
    );

    if (response.Success) {
      toastNotification(
        'success',
        getTranslation('toast.success.templateCreated'),
        3000
      );
      close();
    } else {
      toastNotification('error', getTranslation('toast.error.templateCreated'));
    }
    setLoading(false);
  }

  async function handleUpdateTemplate(template: ITemplate) {
    setLoading(true);

    const response = await UpdateTemplate(
      { ...template, bot_name },
      dispatchApp
    );

    if (response.Success) {
      toastNotification(
        'success',
        getTranslation('toast.success.templateUpdated'),
        3000
      );
      setLoading(false);
      close();
    } else {
      toastNotification('error', getTranslation('toast.error.templateUpdated'));
      setLoading(false);
    }
  }

  async function validateTemplate(currentTemplate: ITemplate) {
    const errors: ITemplateError = { name: '', organization: '', message: '' };

    if (currentTemplate.name === '') {
      errors.name = getTranslation('validations.required', {
        field: getTranslation('name'),
      });
    }

    if (
      currentTemplate.channel === 'sms' &&
      currentTemplate.payload_message.text &&
      currentTemplate.payload_message.text.length > 160
    ) {
      errors.message = getTranslation('validations.smsLimit');
    }

    if (
      currentTemplate.channel === 'falazap' &&
      templateComponents.header.text === ''
    ) {
      errors.falazapTitle = 'Obrigatório fornecer um título';
    }

    if (
      currentTemplate.channel === 'whatsapp' &&
      templateComponents.body === ''
    ) {
      errors.body = getTranslation('validations.required', {
        field: 'Body',
      });
    }
    
    if (currentTemplate.channel === 'falazap' && file) {
      const fileUpload = await handleFileUpload(file);
      if (fileUpload) {
        templateComponents.image = fileUpload.url;
      } else errors.message = getTranslation('toast.error.fileUpload');
    }

    if (templateComponents?.header.format === 'IMAGE' && file) {
      const fileUpload = await handleFileUpload(file);
      if (fileUpload) {
        templateComponents.header.text = fileUpload.url;
      } else errors.message = getTranslation('toast.error.fileUpload');
    }

    setError(errors);

    const templateValidated =
      errors.name === '' &&
      errors.organization === '' &&
      errors.message === '' &&
      error.falazapTitle === '';

    const components: ITemplateComponents[] = [];

    if (templateComponents.header.format !== 'NONE')
      components.push({
        type: templateComponents.header.type,
        text: templateComponents.header.text,
        format: templateComponents.header.format,
      });

    if (
      typeof templateComponents.image === 'string' &&
      currentTemplate.channel === 'falazap'
    )
      components.push({ type: 'IMAGE', text: templateComponents.image });

    if (templateComponents.body)
      components.push({ type: 'BODY', text: templateComponents.body });

    if (templateComponents.footer)
      components.push({ type: 'FOOTER', text: templateComponents.footer });

    if (templateButtons.buttons.length > 0) {
      const buttons: ITemplateButton[] = [];

      templateButtons.buttons.forEach((button) => {
        buttons.push({
          type: button.type,
          text: button.text || null,
          phone_number: button.phone_number
            ? `+55${button.phone_number}`
            : null,
          url: button.url || null,
        });
      });

      if (buttons.length > 0) components.push({ type: 'BUTTONS', buttons });
    }
    if (currentTemplate.channel === 'falazap') {
      currentTemplate.status = 'approved';
    }
    const payloadTemplate: IPayloadTemplate | null =
      currentTemplate.channel === 'whatsapp'
        ? {
            name: currentTemplate.payload_message.template?.name || currentTemplate.name || '',
            category:
              currentTemplate.payload_message.template?.category || 'MARKETING',
            broker:
              currentTemplate.payload_message.template?.broker || 'Meta',
            language:
              currentTemplate.payload_message.template?.language || currentTemplate.language || 'pt_BR',
            components,
          }
        : currentTemplate.channel === 'falazap'
        ? {
            name: currentTemplate.payload_message.template?.name || currentTemplate.name || '',
            language:
              currentTemplate.payload_message.template?.language || currentTemplate.language || 'pt_BR',
            components,
          }
        : null;

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: payloadTemplate,
      },
    }));

    currentTemplate.payload_message = {
      ...currentTemplate.payload_message,
      template: payloadTemplate,
    };

    if (!templateValidated)
      currentTemplate._id
        ? handleUpdateTemplate(currentTemplate)
        : handleCreateTemplate(currentTemplate);
  }

  async function handleChangeGroup(groupName: string) {
    const blocks: string[] = [];

    const response = await GetGroup({ bot_name, groupName }, dispatchApp);

    if (response.Success) {
      const group = response.Data.data;

      const keys = Object.keys(group.blocks.drawflow.Home.data);

      for (const key in keys) {
        const block = group.blocks.drawflow.Home.data[Number(keys[key])];
        blocks.push(block.data.name);
      }
    }
    setBlocks(blocks.sort());

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        bot: {
          ...prev.payload_message.bot,
          group: groupName,
        },
      },
    }));
  }

  function handleChangeQuickReply(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    button: ITemplateButton,
    index: number
  ) {
    const updatedButton: ITemplateButton = {
      ...button,
      text: e.target.value,
    };
    const buttons = templateButtons.buttons;
    buttons[index] = updatedButton;

    setTemplateButtons((prev) => ({
      ...prev,
      buttons,
    }));
  }

  function handleChangeCallToAction(
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    button: ITemplateButton,
    field: ITemplateButtonFields,
    index: number
  ) {
    const updatedButton: ITemplateButton = {
      ...button,
      [field]: e.target.value,
    };

    const buttons = templateButtons.buttons;
    buttons[index] = updatedButton;

    setTemplateButtons((prev) => ({
      ...prev,
      buttons,
    }));
  }

  function handleRemoveButton(button: ITemplateButton) {
    const newButtons = templateButtons.buttons.filter((b) => b !== button);
    setTemplateButtons((prev) => ({
      ...prev,
      buttons: newButtons,
    }));
  }

  function handleChangeBlock(block: string) {
    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        bot: {
          ...prev.payload_message.bot,
          block,
        },
      },
    }));
  }

  function handleChangeHeaderType(format: TemplateComponentFormat) {
    setTemplateComponents((prev) => ({
      ...prev,
      header: {
        ...prev.header,
        format,
        text: format === 'TEXT' ? prev.header.text : '',
      },
    }));
  }

  function handleChangeButtonType(type: ITemplateButtonTypes) {
    const buttons: ITemplateButton[] =
      type === 'PHONE_NUMBER' || type === 'QUICK_REPLY'
        ? ([{ text: '', type }] as ITemplateButton[])
        : [];

    setTemplateButtons(() => ({
      type,
      buttons,
    }));
  }

  function handleChangeChannel(channel: ITemplateChannels) {
    if (channel === 'falazap') handleChangeHeaderType('TEXT');

    setTemplate((prev) => ({
      ...prev,
      channel,
      payload_message: {
        ...prev.payload_message,
        template: emptyTemplate.payload_message.template,
      },
    }));
  }

  function getButtonType(buttons: ITemplateComponents | undefined) {
    if (buttons && buttons.buttons) {
      const buttonType =
        buttons?.buttons[0].type === 'PHONE_NUMBER' ||
        buttons?.buttons[0].type === 'URL'
          ? 'PHONE_NUMBER'
          : buttons?.buttons[0].type === 'QUICK_REPLY'
          ? 'QUICK_REPLY'
          : 'NONE';

      return buttonType;
    } else return 'NONE';
  }

  function handleChangeTemplateCategory(
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) {
    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          category: e.target.value as ITemplateCategories,
          name: prev.payload_message?.template?.name || '',
          components: prev.payload_message?.template?.components || [],
        },
      },
    }));
  }

  function handleChangeTemplateBroker(brokerName: ITemplateBrokers) {
    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          name: prev.payload_message?.template?.name || '',
          components: prev.payload_message?.template?.components || [],
          broker: brokerName
        },
      },
    }));
  }

  function handleChangeTemplateName(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.value.toLowerCase();
    const formattedName = validateTemplateName(name);

    setTemplate((prev) => ({
      ...prev,
      payload_message: {
        ...prev.payload_message,
        template: {
          ...prev.payload_message.template,
          components: prev.payload_message?.template?.components || [],
          name: formattedName,
        },
      },
      name: formattedName,
    }));
  }

  function handleTemplateImportName(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.value.toLowerCase();
    const formattedName = validateTemplateName(name);

    setTemplateImportName((prev) => formattedName)
  }

  const [isOpenModalImportTemplate, setIsOpenModalImportTemplate ] = useState<boolean>(false)

  function handleCloseModalImportTemplate() {
    setIsOpenModalImportTemplate(false)
    setTemplateImportName('')
  }

  function handleOpenModalImportTemplate() {
    setIsOpenModalImportTemplate(true)
  }

  return (
    <>
      <Dialog
        open={isOpenModalImportTemplate} 
        maxWidth="md" 
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle>
          <Typography variant="subtitle1" className={classes.title}>
            Importar template
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleCloseModalImportTemplate()}
            aria-label="close"
            classes={{ root: classes.dialogIconContainer }}
          >
            <CloseIcon fontSize='small'/>
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <TextField
            variant="standard"
            label={getTranslation('name')}
            fullWidth
            disabled={isNotEditable}
            value={templateImportName}
            onChange={handleTemplateImportName}
            helperText={error.name}
            error={!!error.name}
          />

          <MttButton
            disabled={isNotEditable || isLoadingTemplateImport || loading}
            variant="contained"
            color="primary"
            onClick={() => verifyTemplateOnMeta()}
          >
            {getTranslation('footerButtons.import')}
            {loading && <CircularProgress color="inherit" size={18} />}
          </MttButton>
        </DialogContent>
      </Dialog>

      <NewCampaignContainer>
        <NewCampaignFieldsContainer>
          <NewCampaignFields newWidth={isWhatsApp ? '75%' : '100%'}>
            <BoxContainer>
              <TextContainer>
                <TextField
                  variant="outlined"
                  label={getTranslation('name')}
                  fullWidth
                  disabled={isNotEditable}
                  value={template.name}
                  onChange={handleChangeTemplateName}
                  helperText={error.name}
                  error={!!error.name}
                />
              </TextContainer>
            </BoxContainer>

            <BoxContainer>
              <TextContainer>
                <TextField
                  variant="outlined"
                  label={getTranslation('description')}
                  fullWidth
                  disabled={isNotEditable}
                  value={template.description}
                  onChange={(e) =>
                    setTemplate((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </TextContainer>
            </BoxContainer>

            <BoxContainer>
              <TextContainer>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getTranslation('language')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={template.language}
                    disabled={isNotEditable}
                    onChange={(e) =>
                      setTemplate((prev) => ({
                        ...prev,
                        language: e.target.value as ITemplateLanguages,
                      }))
                    }
                    label={getTranslation('language')}
                  >
                    <MenuItem value={'pt_BR'}>
                      {getTranslation('portuguese')}
                    </MenuItem>
                    <MenuItem value={'es'}>
                      {getTranslation('spanish')}
                    </MenuItem>
                    <MenuItem value={'en_US'}>
                      {getTranslation('english')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </TextContainer>

              <TextContainer>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getTranslation('channel')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    disabled={isNotEditable}
                    value={template.channel}
                    onChange={(e) =>
                      handleChangeChannel(e.target.value as ITemplateChannels)
                    }
                    label={getTranslation('channel')}
                  >
                    <MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
                    <MenuItem value={'falazap'}>Falazap</MenuItem>
                    <MenuItem value={'sms'}>SMS</MenuItem>
                    <MenuItem disabled value={'email'}>
                      E-mail
                    </MenuItem>
                    <MenuItem disabled value={'ura'}>
                      URA
                    </MenuItem>
                  </Select>
                </FormControl>
              </TextContainer>
            </BoxContainer>

            <BoxContainer>
              {isWhatsApp && (
                <TextContainer>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {getTranslation('broker')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled={isNotEditable}
                      value={template.payload_message.template?.broker}
                      onChange={(e) => handleChangeTemplateBroker(e.target.value as ITemplateBrokers)}
                      onBlur={() => verifyTemplateOnDatabase()}
                      label={getTranslation('broker')}
                      defaultValue={template.payload_message.template?.broker}
                    >
                      <MenuItem value={'Meta'}>
                        Meta
                      </MenuItem>
                      <MenuItem value={'Infobip'}>
                        Infobip
                      </MenuItem>
                      <MenuItem value={'Blip'}>
                        Blip
                      </MenuItem>
                      <MenuItem value={'Twillio'}>
                        Twillio
                      </MenuItem>
                      <MenuItem value={'Interaxa'}>
                        Interaxa
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TextContainer>
              )}
            </BoxContainer>

            <BoxContainer>
              {isWhatsApp && (
                <TextContainer>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {getTranslation('category')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled={isNotEditable}
                      value={template.payload_message.template?.category}
                      onChange={(e) => handleChangeTemplateCategory(e)}
                      label={getTranslation('category')}
                    >
                      <MenuItem value={'MARKETING'}>
                        {getTranslation('modal.campaigns.template.marketing')}
                      </MenuItem>
                      <MenuItem value={'OTP'}>
                        {getTranslation('modal.campaigns.template.otp')}
                      </MenuItem>
                      <MenuItem value={'UTILITY'}>
                        {getTranslation('modal.campaigns.template.utility')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TextContainer>
              )}
            </BoxContainer>

            {isWhatsApp && (
              <BoxContainer>
                <TextContainer>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {getTranslation('group')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled={isNotEditable}
                      value={template.payload_message.bot.group}
                      onChange={(e) => {
                        handleChangeGroup(e.target.value as string);
                      }}
                      label={getTranslation('group')}
                    >
                      {groups.map((group) => (
                        <MenuItem key={group} value={group}>
                          {group}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TextContainer>

                <TextContainer>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {getTranslation('block')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled={isNotEditable}
                      value={template.payload_message.bot.block}
                      onChange={(e) =>
                        handleChangeBlock(e.target.value as string)
                      }
                      label={getTranslation('block')}
                    >
                      {blocks.map((block) => (
                        <MenuItem key={block} value={block}>
                          {block}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TextContainer>
              </BoxContainer>
            )}

            {isWhatsApp && (
              <>
                <BoxContainer>
                  <Typography
                    variant="h6"
                    children={getTranslation('header')}
                    color="primary"
                  />
                </BoxContainer>
                <BoxContainer>
                  <TextContainer style={{ width: 'fit-content' }}>
                    <FormControl variant="outlined">
                      <Select
                        disabled={isNotEditable}
                        value={templateComponents.header.format}
                        onChange={(e) =>
                          handleChangeHeaderType(
                            e.target.value as TemplateComponentFormat
                          )
                        }
                      >
                        <MenuItem value={'NONE'}>
                          {getTranslation('none')}
                        </MenuItem>
                        <MenuItem value={'TEXT'}>
                          {getTranslation('text')}
                        </MenuItem>
                        <MenuItem disabled value={'IMAGE'} hidden>
                          {getTranslation('image')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TextContainer>
                  {templateComponents.header.format === 'TEXT' && (
                    <TextContainer>
                      <OutlinedInput
                        fullWidth
                        disabled={isNotEditable}
                        value={templateComponents.header.text}
                        onChange={(e) =>
                          setTemplateComponents((prev) => ({
                            ...prev,
                            header: {
                              ...prev.header,
                              text: e.target.value,
                            },
                          }))
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            {templateComponents.header.text?.length || '0'}/60
                          </InputAdornment>
                        }
                      />
                    </TextContainer>
                  )}
                  {/* aqui img */}
                  {templateComponents.header.format === 'IMAGE' && (
                    <>
                      <TextContainer>
                        <OutlinedInput
                          fullWidth
                          disabled={isNotEditable}
                          value={templateComponents.header.text || file?.name}
                          onChange={(e) =>
                            setTemplateComponents((prev) => ({
                              ...prev,
                              header: {
                                ...prev.header,
                                text: e.target.value,
                              },
                            }))
                          }
                        />
                      </TextContainer>
                      <TextContainer>
                        <MttButton
                          className={classes.selectFileButton}
                          fullWidth
                          onClick={() => setImportFile(true)}
                        >
                          {getTranslation('loadFile')}
                        </MttButton>
                      </TextContainer>
                    </>
                  )}
                </BoxContainer>
                <div>
                  <Typography
                    variant="h6"
                    children={getTranslation('body')}
                    color="primary"
                  />
                </div>
                <BoxContainer>
                  <TextContainer style={{ height: 'auto' }}>
                    <OutlinedInput
                      fullWidth
                      disabled={isNotEditable}
                      multiline
                      rows={4}
                      value={templateComponents.body}
                      inputProps={{ maxLength: 1024 }}
                      onChange={(e) =>
                        setTemplateComponents((prev) => ({
                          ...prev,
                          body: e.target.value,
                        }))
                      }
                      error={!!error.body}
                      endAdornment={
                        <InputAdornment position="end">
                          {templateComponents.body?.length || '0'}/1024
                        </InputAdornment>
                      }
                    />
                    {!!error.body && (
                      <FormHelperText error>{error.body}</FormHelperText>
                    )}
                  </TextContainer>
                </BoxContainer>
                <BoxContainer>
                  <Typography
                    variant="h6"
                    children={getTranslation('footer')}
                    color="primary"
                  />
                </BoxContainer>
                <BoxContainer>
                  <TextContainer>
                    <OutlinedInput
                      disabled={isNotEditable}
                      fullWidth
                      value={templateComponents.footer}
                      onChange={(e) =>
                        // setFooter(e.target.value)
                        setTemplateComponents((prev) => ({
                          ...prev,
                          footer: e.target.value,
                        }))
                      }
                      inputProps={{ maxLength: 60 }}
                      endAdornment={
                        <InputAdornment position="end">
                          {templateComponents.footer?.length || '0'}/60
                        </InputAdornment>
                      }
                    />
                  </TextContainer>
                </BoxContainer>
                <BoxContainer>
                  <Typography
                    variant="h6"
                    children={getTranslation('buttons')}
                    color="primary"
                  />
                </BoxContainer>
                <BoxContainer>
                  <TextContainer>
                    <FormControl variant="outlined">
                      <Select
                        disabled={isNotEditable}
                        defaultValue={'NONE'}
                        value={templateButtons.type}
                        onChange={(e) =>
                          handleChangeButtonType(
                            e.target.value as ITemplateButtonTypes
                          )
                        }
                      >
                        <MenuItem value={'NONE'}>
                          {getTranslation('none')}
                        </MenuItem>
                        <MenuItem value={'PHONE_NUMBER'}>
                          {getTranslation('callToAction')}
                        </MenuItem>
                        <MenuItem value={'QUICK_REPLY'}>
                          {getTranslation('quickReply')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TextContainer>
                </BoxContainer>
                {templateButtons.buttons.map((button, index) =>
                  templateButtons.type === 'QUICK_REPLY' ? (
                    <BoxContainer key={index}>
                      <TextContainer autoheight>
                        <Typography
                          children={getTranslation('buttonText')}
                          variant="subtitle2"
                        />
                        <TextContainer>
                          <OutlinedInput
                            disabled={isNotEditable}
                            value={button.text}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) =>
                              handleChangeQuickReply(e, button, index)
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                {button.text?.length || '0'}/25
                              </InputAdornment>
                            }
                          />
                          {templateButtons.buttons.length > 1 && (
                            <IconButton
                              disabled={isNotEditable}
                              onClick={() => handleRemoveButton(button)}
                            >
                              <Close />
                            </IconButton>
                          )}
                        </TextContainer>
                      </TextContainer>
                    </BoxContainer>
                  ) : (
                    <BoxContainer>
                      <BoxContainer>
                        <TextContainer autoWidth autoheight>
                          <Typography
                            children={getTranslation('actionType')}
                            variant="subtitle2"
                          />
                          <FormControl variant="outlined">
                            <Select
                              defaultValue={'URL'}
                              disabled={isNotEditable}
                              value={button.type}
                              onChange={(e) =>
                                handleChangeCallToAction(
                                  e,
                                  button,
                                  'type',
                                  index
                                )
                              }
                            >
                              <MenuItem value={'URL'}>
                                {getTranslation('accessSite')}
                              </MenuItem>
                              <MenuItem value={'PHONE_NUMBER'}>
                                {getTranslation('call')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TextContainer>

                        {button.type === 'URL' ? (
                          <>
                            <TextContainer autoWidth autoheight>
                              <Typography
                                children={getTranslation('buttonText')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                value={button.text}
                                disabled={isNotEditable}
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'text',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.text?.length || '0'}/25
                                  </InputAdornment>
                                }
                              />
                            </TextContainer>

                            <TextContainer autoWidth autoheight>
                              <Typography
                                children={getTranslation('urlType')}
                                variant="subtitle2"
                              />
                              <FormControl variant="outlined">
                                <Select
                                  defaultValue={'static'}
                                  value={'static'}
                                >
                                  <MenuItem value={'static'}>Estática</MenuItem>
                                  <MenuItem value={'dynamic'}>
                                    Dinâmica
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </TextContainer>

                            <TextContainer autoWidth autoheight>
                              <Typography
                                children={getTranslation('urlSite')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                value={button.url}
                                disabled={isNotEditable}
                                inputProps={{ maxLength: 2000 }}
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'url',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.url?.length || '0'}/2000
                                  </InputAdornment>
                                }
                              />

                              {templateButtons.buttons.length > 1 && (
                                <IconButton
                                  onClick={() => handleRemoveButton(button)}
                                  disabled={isNotEditable}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </TextContainer>
                          </>
                        ) : (
                          <>
                            <TextContainer autoWidth autoheight>
                              <Typography
                                children={getTranslation('buttonText')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                disabled={isNotEditable}
                                value={button.text}
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'text',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.text?.length || '0'}/25
                                  </InputAdornment>
                                }
                              />
                            </TextContainer>

                            <TextContainer autoWidth autoheight>
                              <Typography
                                children={getTranslation('country')}
                                variant="subtitle2"
                              />
                              <FormControl variant="outlined">
                                <Select
                                  disabled={isNotEditable}
                                  defaultValue={'BR +55'}
                                  value={'BR +55'}
                                >
                                  <MenuItem value={'BR +55'}>BR +55</MenuItem>
                                </Select>
                              </FormControl>
                            </TextContainer>

                            <TextContainer autoWidth autoheight>
                              <Typography
                                children={getTranslation('phone')}
                                variant="subtitle2"
                              />
                              <OutlinedInput
                                disabled={isNotEditable}
                                value={button.phone_number}
                                inputProps={{ maxLength: 20 }}
                                placeholder="(XX) XXXXXXXXX"
                                onChange={(e) =>
                                  handleChangeCallToAction(
                                    e,
                                    button,
                                    'phone_number',
                                    index
                                  )
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {button.phone_number?.length || '0'}/20
                                  </InputAdornment>
                                }
                              />

                              {templateButtons.buttons.length > 1 && (
                                <IconButton
                                  onClick={() => handleRemoveButton(button)}
                                  disabled={isNotEditable}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </TextContainer>
                          </>
                        )}
                      </BoxContainer>
                    </BoxContainer>
                  )
                )}

                {templateButtons.type !== 'NONE' && (
                  <div style={{ padding: 4, height: 'auto' }}>
                    <MttButton
                      variant="contained"
                      startIcon={<AddIcon />}
                      disabled={
                        templateButtons.buttons.length >= buttonsLimit ||
                        isNotEditable
                      }
                      onClick={() => {
                        setTemplateButtons((prev) => ({
                          ...prev,
                          buttons: [
                            ...prev.buttons,
                            {
                              text: '',
                              type: templateButtons.type,
                            },
                          ] as ITemplateButton[],
                        }));
                      }}
                    >
                      {getTranslation('add')} {getTranslation('button')}
                    </MttButton>
                  </div>
                )}
              </>
            )}

            {isSms && (
              <>
                <BoxContainer>
                  <TextContainer autoheight>
                    <Typography
                      variant="h6"
                      children={getTranslation('text')}
                      color="primary"
                    />
                  </TextContainer>
                </BoxContainer>
                <BoxContainer>
                  <TextContainer autoheight>
                    <TextField
                      multiline
                      disabled={isNotEditable}
                      minRows={4}
                      variant="outlined"
                      fullWidth
                      value={template.payload_message.text}
                      error={!!error.message}
                      helperText={error.message}
                      onChange={(e) =>
                        setTemplate((prev) => ({
                          ...prev,
                          payload_message: {
                            ...prev.payload_message,
                            text: e.target.value,
                          },
                        }))
                      }
                    />
                  </TextContainer>
                </BoxContainer>
              </>
            )}
            {isFalazap && (
              <FormFalazap
                error={error}
                setImportFile={setImportFile}
                file={file}
                setTemplateComponents={setTemplateComponents}
                templateComponents={templateComponents}
                isNotEditable={isNotEditable}
              />
            )}
          </NewCampaignFields>

          {isWhatsApp && (
            <Preview
              body={templateComponents.body}
              footer={templateComponents.footer}
              header={templateComponents.header}
              templateButtons={templateButtons}
              imgUrl={imageUrl}
            />
          )}
        </NewCampaignFieldsContainer>
      </NewCampaignContainer>

      <StyledGridButtons>
        <StyledRightButtons>
          <MttButton
            disabled={isNotEditable || isLoadingTemplateImport || loading}
            variant="contained"
            color="primary"
            startIcon={<LibraryAddOutlined />}
            onClick={() => handleOpenModalImportTemplate()}
          >
            {getTranslation('footerButtons.import')}
            {isLoadingTemplateImport && <CircularProgress color="inherit" size={18} />}
          </MttButton>

          <MttButton
            disabled={isNotEditable || isLoadingTemplateImport || loading}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => validateTemplate(template)}
          >
            {getTranslation('footerButtons.saveAndExit')}
            {loading && <CircularProgress color="inherit" size={18} />}
          </MttButton>
        </StyledRightButtons>
      </StyledGridButtons>
    </>
  );
}
