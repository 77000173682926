import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';
import { themeDefault } from 'themes/DefaultTheme';


export const NewCampaignFields = styled.div<{ newWidth?: string }>`
  overflow-x: hidden;
  width: ${(props) => props.newWidth};
`;
export const NewCampaignFieldsContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const NewCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 74px);

  overflow: auto;
`;

export const MessageButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 0;
  padding: 0 6px;
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`

export const useStyles = makeStyles((theme: Theme) => ({
  selectFileButton: {
    maxWidth: 225,
    height: '50px',
    background: `${themeDefault.palette.primary.main}`,
    color: '#fff',

    '&:hover': {
      background: '#512d9a',
      color: '#fff',
    },
  },
  title: {
    flexGrow: 1,
  },
  toolBar: {
    gap: '20px'
  },
  dialogContainer: {
    width: '20vw',
    minWidth: '200px',
    padding: '18px 30px 24px 30px',
    position: 'absolute',
    top: theme.spacing(5),
    gap: '4px',
  },
  dialogIconContainer: {
    padding: '8px',
    marginRight: '-12px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: 0,
  },
}));
