import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
  style?: React.CSSProperties;
}

const NewFlexBlockIcon: React.FC<ISVGProps> = (props) => {
  const { size, color, style } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || '24px'}
      height={size || '24px'}
      fill={color || '#424242'}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512.188 512.188"
      xmlSpace="preserve"
      style={style}
    >
      <g>
        <g>
          <path d="M498.773,229.854l-99.789-72.073c-7.834-5.658-18.039-6.426-26.641-2.039c-8.602,4.403-13.943,13.133-13.943,22.801    v43.418h-76.8v-68.267c0-61.167-49.766-110.933-110.933-110.933h-28.578C129.186,15.923,105.148,0.094,76.8,0.094    C34.458,0.094,0,34.551,0,76.894s34.458,76.8,76.8,76.8c28.348,0,52.386-15.829,65.289-42.667h28.578    c23.526,0,42.667,19.14,42.667,42.667v68.267h-71.245c-12.902-26.837-36.941-42.667-65.289-42.667    c-42.342,0-76.8,34.458-76.8,76.8s34.458,76.8,76.8,76.8c28.348,0,52.386-15.829,65.289-42.667h71.245v68.267    c0,23.526-19.14,42.667-42.667,42.667h-28.578c-12.902-26.837-36.941-42.667-65.289-42.667c-42.342,0-76.8,34.458-76.8,76.8    s34.458,76.8,76.8,76.8c28.348,0,52.386-15.829,65.289-42.667h28.578c61.167,0,110.933-49.766,110.933-110.933v-59.733h76.8    v34.884c0,9.668,5.342,18.398,13.943,22.801c8.602,4.395,18.825,3.627,26.641-2.039l99.789-72.073    c8.525-6.161,13.414-15.727,13.414-26.24S507.298,236.015,498.773,229.854z" />
        </g>
      </g>
    </svg>
  );
};

export default NewFlexBlockIcon;
