import { Grid, makeStyles, Theme } from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';
import { themeDefault } from 'themes/DefaultTheme';

export const ContainerDialog = styled(MttGrid)`
  overflow-y: hidden;

  height: 100%;
  margin-top: 64px;
  padding: 12px;

  .MuiAppBar-colorPrimary {
    background-color: ${themeDefault.palette.primary.main};
    position: absolute;
  }

  .MuiOutlinedInput-input {
    background-color: #fff;
  }

  .MuiFormControl-root {
    background-color: #fff;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export const GridSearch = styled(MttGrid)`
  margin-top: 16px;
`;

export const NewCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NewCampaignFields = styled.div`
  height: 85%;
  overflow-x: hidden;
  padding-right: 16px;
`;

export const StyledGridButtons = styled(MttGrid).attrs({
  className: 'grayButtonMaterial',
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 16px;

  .MuiButton-containedPrimary {
    height: 56px;
  }
`;

export const ContentContainer = styled(Grid)`
  overflow-y: auto;
  padding: 20px;
  margin-top: 16px;
`;

export const HeaderButtonsContainer = styled.div`
  .MuiButton-outlined {
    margin-right: 16px;
    max-height: 30px;
  }

  .MuiButton-contained {
    width: auto;
  }
`;

export const SearchAndTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledRightButtons = styled(MttGrid)`
  width: 360px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export const TextContainer = styled(MttGrid)<{ autoWidth?: boolean; autoheight?: boolean }>`
  width: ${(props) => (!props.autoWidth ? '100%' : 'auto')};
  height: ${(props) => (!props.autoheight ? '56px' : 'auto')};
  min-height: 56px;
  padding: 8px;
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ContainerHeader = styled(MttGrid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiIconButton-root {
    padding: 4px;
    margin: 0 12px;
  }

  .MuiButton-contained {
    height: 32px;

    color: #ffffff;
    background-color: #34ac26;

    &:hover {
      background-color: #2d9421;
    }
  }
`;

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
    background: theme.palette.primary.main,
  },
  title: {
    flex: 1,
  },
  table: {
    minWidth: 650,
    margin: 'auto',
    boxShadow: 'none',
    height: 'auto',
  },
  tableContainer: {
    height: '60vh',
    marginTop: 24,
  },
  tableFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 32,
  },
}));
